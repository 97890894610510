import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../components/Others/Banner";
import BottomNav from "../components/Others/BottomNav";
import Card from "../components/Others/Card";
import Timmer from "../components/Others/Timmer";
import { Container, LinkBtn } from "../styles/basic.style";
import { PrepMessage, WhatappLink, Whatsapp } from "../styles/timmer.style";
import { getUser } from "../redux/auth.slice";

const Home = () => {
  const { wallet, user, access_token } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const [ balance, setBalance ] = useState(wallet)

  const getWallet =  async () => {
    await dispatch(getUser({token: access_token})).unwrap();
    setBalance(wallet)
  }

  useEffect(()=> {
    getWallet();
  }, [balance.total_balance, balance.amount_made_today])

  return (
    <>
      <Banner
        page_message={`Hi, ${user?.full_name ? user?.full_name.split(" ")[0] : "New User"
          }`}
      >
        <Timmer wallet={getWallet}/>
      </Banner>
      <Container>
        <PrepMessage style={{ marginBottom: "1rem" }}>
          <h4>Perform your daily task and earn</h4>
        </PrepMessage>
        <div style={{ display: "flex" }}>
          <Card
            add_margin="1rem"
            headings="Total Earning"
            value={wallet.total_balance.toFixed(2)}
            img="/t-cash.png"
            sign="$"
          />

          <Card
            headings="Daily Earning"
            value={wallet.amount_made_today.toFixed(2)}
            img="/Cash.png"
            sign="$"
          />
        </div>
        <PrepMessage style={{ margin: "1rem 0 2rem 0" }}>
          <h4>
            Compound interest is the eight wonder of the world. He who
            understands it. Earns it. He who doesn't pay's it ----- Albeit
            Einstein
          </h4>
        </PrepMessage>
        <div style={{ textAlign: "center", marginBottom: '7rem' }}>
          <LinkBtn href='https://www.cretask.com/cretask.apk' download bgcolor='green'>Download Our App</LinkBtn>
        </div>
        <WhatappLink href="https://wa.me/16462380422">
          <Whatsapp src='./whatsapp.png' />
        </WhatappLink>
      </Container>

      <BottomNav />
    </>
  );
};

export default Home;
