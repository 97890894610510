import React, { useState } from "react";
import Banner from "../components/Others/Banner";
import {
  IconWrapper,
  Input,
  InputWrapper,
  SubmitBtn,
} from "../styles/basic.style";
import BottomNav from "../components/Others/BottomNav";
import { AuthContainer } from "../styles/auth.style";
import UserCard from "../components/Profile/UserCard";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileFields } from "../data";
import { handleError } from "../helpers";
import { toast } from "react-toastify";
import { logout, updateUser } from "../redux/auth.slice";
import Payment from "./Payment";
import { GoOutBtn } from "../styles/profile.style";

const Profile = () => {
  const { loading, user, access_token } = useSelector((state) => state.auth);
  const [values, setValues] = useState({
    full_name: user.full_name,
    wallet_address: user.wallet_address,
    email: user.email,
  });
  const dispatch = useDispatch();
  const [paymentData, setPaymentData] = useState(null);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };
  const handleSubmit = async () => {
    const valid = handleError(values);

    if (!valid) {
      return;
    }
    try {
      values.token = access_token;
      const data = await dispatch(updateUser(values)).unwrap();
      toast.success(data.message, {
        position: "top-right",
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <>
      {!paymentData && (
        <div>
          <Banner page_message="Profile">
            <UserCard setPaymentData={setPaymentData} />
          </Banner>
          <AuthContainer>
            {updateProfileFields.map((field, i) => (
              <InputWrapper key={i}>
                <IconWrapper>{field.icon}</IconWrapper>
                <Input
                  placeholder={field.placeholder}
                  name={field.name}
                  value={values[field.name]}
                  type={field.type}
                  onChange={(e) => handleChange(e)}
                />
              </InputWrapper>
            ))}
            <SubmitBtn
              marginBottom="3rem"
              disabled={loading}
              onClick={handleSubmit}
            >
              {!loading ? "Submit" : "Loading...."}
            </SubmitBtn>

            <GoOutBtn
              color="white"
              bgColor="red"
              onClick={() => {
                dispatch(logout());
              }}
            >
              Log Out
            </GoOutBtn>
          </AuthContainer>

          <BottomNav />
        </div>
      )}

      {paymentData && (
        <Payment data={paymentData} setPaymentData={setPaymentData} />
      )}
    </>
  );
};

export default Profile;
