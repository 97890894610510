import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginFields } from "../../data";
import { handleError } from "../../helpers";
import { fetchLoginUser } from "../../redux/auth.slice";
import {
  IconWrapper,
  Input,
  InputWrapper,
  // StyledLink,
  SubmitBtn,
} from "../../styles/basic.style";
const Login = () => {
  const [values, setValues] = useState({ user_id: "", password: "" });
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async () => {
    const valid = handleError(values);

    if (!valid) {
      return;
    }

    try {
      const data = await dispatch(fetchLoginUser(values)).unwrap();
      navigate("/");

      toast.success(data.message, {
        position: "top-right",
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <>
      <form>
        {loginFields.map((field, i) => (
          <InputWrapper key={i}>
            <IconWrapper>{field.icon}</IconWrapper>
            <Input
              required
              placeholder={field.placeholder}
              name={field.name}
              onChange={(e) => handleChange(e)}
              type={field.type}
            />
          </InputWrapper>
        ))}{" "}
        <SubmitBtn onClick={handleSubmit} disabled={loading}>
          {!loading ? "Submit" : "Loading...."}
        </SubmitBtn>
      </form>
    </>
  );
};

export default Login;
