import React from 'react'
import { useLocation } from 'react-router-dom'
import { bottomLinks } from '../../data'
import { P, StyledLink } from '../../styles/basic.style'
import { BLink, NavContainer } from '../../styles/bottom_nav.style'

const BottomNav = () => {

  const location = useLocation();
  const { pathname } = location;

  return (
    <NavContainer>

      {
        bottomLinks.map(link => (
          <BLink key={link.name}>
            <StyledLink to={link.link}>
              {pathname === link.link ? <img src={link.iconActive} alt="" /> : <img src={link.icon} alt="" />}
              <P fontSize='0.8rem' marginTop='0.2rem' color='#808080e3' >{link.name}</P>
            </StyledLink>
          </BLink>

        ))
      }
    </NavContainer>
  )
}

export default BottomNav