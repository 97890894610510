import React from "react";
import Banner from "../components/Others/Banner";
import { Container } from "../styles/basic.style";
import BottomNav from "../components/Others/BottomNav";
import UserCard from "../components/Profile/UserCard";
import Card from "../components/Others/Card";
import { useSelector } from "react-redux";

const Wallet = () => {
  const { wallet, task } = useSelector((state) => state.auth);

  const data = [
    "The recharge address is USDT - TRC20 / ERC20, please check carefully before recharging.",
    "The minimum deposit amount is 15 ERC20/TRC20. Amounts below 15 ERC20/TRC20 will not be credited to the account.",
    "After the recharge is successful, it will automatically arrive in the account within 3-5 minutes.",
    "You need to obtain the latest recharge address for each recharge. Please do not save the recharge address multiple times.",
    "15 - 99 USDT= 1.2% Interest rate",
    "100 - 299 USDT= 1.8 % Interest rate",
    "300 - 999 USDT  =    2.20 % Interest rate",
    "1000 - 3000 USDT = 2.70 % Interest rate",
    "3000 - 10,000 USDT= 3.20 % Interest rate",
    "10,000 - 99, 999 USDT= 3.70 % Interest rate",
  ]

  return (
    <>
      <Banner page_message="Wallet">
        <UserCard />
      </Banner>
      <p style={{ margin: "5% 5% 0 5%" }}>Notice:</p>
      <ul style={{ margin: "5% 5% 0 5%" }}>
        {data.map((item)=> <li key={item}>{item}</li>)}
      </ul>
      <Container>
        
        <div style={{ display: "flex", marginBottom: "1rem" }}>
          <Card
            add_margin="1rem"
            headings="Total Balance"
            value={wallet.total_balance}
            sign="$"
            img="/moneybag.png"
          />
          <Card
            headings="Daily Earnings"
            value={wallet.amount_made_today}
            sign="$"
            img="/Cash.png"
          />
        </div>
        <div style={{ display: "flex", marginBottom: "1rem" }}>
          <Card
            add_margin="1rem"
            headings="Today Remaining"
            value={task.total_remain_day_click}
            img="/wid.png"
          />
          <Card
            headings="Today Earning"
            value={wallet.total_balance}
            img="/t-cash.png"
            sign="$"
          />
        </div>
      </Container>
      <BottomNav />
    </>
  );
};

export default Wallet;
