import styled from "styled-components";
import { mobile } from "./responsive.style";

export const TimmerContainer = styled.div`
  width: 90%;
  max-width: 300px;
  margin: -8rem auto 2rem auto;
  background-image: url("/banner.png");
  border-radius: 5px;
  height: 370px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
  text-align: center;
  ${mobile({
    height: "330px",
  })}
`;

export const PrepMessage = styled.div`
  background: rgba(240, 238, 238, 0.26);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  color: #656565;
  text-align: center;
  padding: 1rem;
`;

export const Tick = styled.button`
  position: absolute;
  border: none;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  color: black;
  font-size: 1.5rem;
  padding: 0.5rem 2rem;
  border-radius: 20px;
`;

export const ClickHand = styled.span`
  position: fixed;
  right: 10px;
`;

export const Whatsapp = styled.img`
position: absolute;
bottom: 5%;
right: 10%;
`
export const WhatappLink = styled.a`
color : 'black';
text-decoration: none;
position: fixed;
bottom: 10%;
right: 10%;
`
