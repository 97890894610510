import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { countries, signupFields } from '../../data'
import { handleError } from '../../helpers'
import { fetchAuthUser } from '../../redux/auth.slice'
import { IconWrapper, Input, InputWrapper, Select, SubmitBtn } from '../../styles/basic.style'


const Signup = () => {

  const [values, setValues] = useState({ user_id: '', email: '', password: '', confirm_password: '', country: '', country_code: "", referred_by: '' });
  const { loading } = useSelector((state) => state.auth)
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleCountryChange = (e) => {
    const convertToarr = e.target.value.split('|');
    setValues({ ...values, country_code: convertToarr[0], country: convertToarr[1] });

  }

  const handleSubmit = async () => {



    const valid = handleError(values);

    if (!valid) {
      return;
    }

    try {
      const data = await dispatch(fetchAuthUser(values)).unwrap();
      navigate('/')

      toast.success(data.message, {
        position: "top-right",
        autoClose: 5000,
      });


    } catch (error) {

      toast.error('Something went wrong', {
        position: "top-right",
        autoClose: 5000,
      });
    }
  }


  return (
    <>
      {
        signupFields.map((field, i) => (
          <InputWrapper key={i}>
            <IconWrapper >
              {field.icon}
            </IconWrapper>
            < Input placeholder={field.placeholder} name={field.name} type={field.type} onChange={((e) => handleChange(e))} />
          </InputWrapper>
        ))


      }
      <Select name='country' onChange={(e) => handleCountryChange(e)}>
        <option value="">Select Country</option>
        {
          countries.map((country) => (
            <option key={country.name} value={country.code + '|' + country.name}>{country.name}</option>
          ))
        }

      </Select>
      < SubmitBtn disabled={loading} onClick={handleSubmit}>{!loading ? 'Submit' : 'Loading....'}</SubmitBtn>
    </>
  )
}

export default Signup