import styled from "styled-components";

export const PaymentContainer = styled.div`
 display: flex;
 padding: 1rem;
 align-items: center;
`

export const PaymentImage = styled.img`
width :70px;
box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
border-radius: 30px;
`
export const MessageBox = styled.p`
 width: 90%;
  margin: -7rem auto 1rem auto;
  height: 18vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`

export const RvBtn = styled.button`
width: 100%;
background-color: white;
padding: 1rem;
border-radius: 10px 10px 0 0;
border: 1px solid #FF2020;
position: absolute;
bottom: 0;
&:hover {
        background-color: #FF2020;
        color: white;
    }
&:active {
        background-color: #FF2020;
        color: white;
    }
`