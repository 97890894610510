import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { privateEndpoints } from "../../config/api.request";
import { updateDailyClick } from "../../redux/auth.slice";
import { ClickHand, Tick, TimmerContainer } from "../../styles/timmer.style";

const Timmer = (props) => {
  const { task } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [timerView, setTimerView] = useState("");
  const dispatch = useDispatch();

  const date = new Date(task.last_time_complete_task);
  const next_date = new Date(task.last_time_complete_task);
  const next_24hours = 1440 * 60 * 1000;
  next_date.setTime(date.getTime() + next_24hours);

  const now = new Date().getTime();

  const difference = next_date.getTime() - now;

  const handleClick = async () => {
    if (difference < 0) {
      setLoading(true);
      await privateEndpoints.doTask(dispatch, updateDailyClick);
      props.wallet();
      setLoading(false);
    }
  };

  useEffect(() => {
    const i = setInterval(() => {
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimerView(
        `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes
        }:${seconds < 10 ? "0" + seconds : seconds}`
      );
      if (difference < 0) {
        clearInterval(i);
        setTimerView(task.total_remain_day_click + " Task");
      }
    }, 1000);
    return () => clearInterval(i);
  }, [timerView, task]);

  return (
    <TimmerContainer>
      <Tick onClick={handleClick} disabled={loading}>
        {timerView === "" || loading !== true ? (
          <ClickHand>
            <img src="click.gif" alt="" srcSet="" />
          </ClickHand>
        ) : null}

        {loading ? "Loading.." : timerView}
      </Tick>
    </TimmerContainer>
  );
};

export default Timmer;
