import React, { useState } from "react";
import Login from "../components/Authenticaation/Login";
import Signup from "../components/Authenticaation/Signup";
import Banner from "../components/Others/Banner";
import { AuthContainer, Tab, TabContainer } from "../styles/auth.style";
import { BannerLogo } from "../styles/banner.style";
import { Container } from "../styles/basic.style";

const Auth = () => {
  const [tabs, SetTabs] = useState({
    login: true,
    signup: false,
  });
  const switchTab = (tab) => {
    if (tab === "login") {
      SetTabs((prev) => ({
        ...prev,
        login: true,
        signup: false,
      }));
    } else {
      SetTabs((prev) => ({
        ...prev,
        signup: true,
        login: false,
      }));
    }
  };
  return (
    <>
      <Banner>
        <BannerLogo src="/cretask.jpeg" />
      </Banner>
      <Container>
        <TabContainer>
          <Tab active={tabs.login} onClick={() => switchTab("login")}>
            Login
          </Tab>
          <Tab active={tabs.signup} onClick={() => switchTab("signup")}>
            Signup
          </Tab>
        </TabContainer>
        <AuthContainer>
          {tabs.login && <Login />}
          {tabs.signup && <Signup />}
        </AuthContainer>
      </Container>
    </>
  );
};

export default Auth;
