import { toast } from "react-toastify";

export const handleError = (values) => {

    const err = [];
    const omit = ['referred_by'];
    Object.entries(values).map(([key, value]) => {
        if (value === '' && !omit.includes(key)) {
            err.push(key);
            toast.error(`${key} field is required`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    })

    if (err.length > 0) {
        return false;
    }

    if (values.confirm_password) {
        if (values.confirm_password !== values.password) {
            toast.error('Password does not match', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }

    }

    return true;


}
