import styled from "styled-components";
import { mobile } from "./responsive.style";

export const TabContainer = styled.div`
  display: flex;
  /* width: 35%; */
  margin: 0 auto;
  margin-top: 1rem;
  justify-content: space-evenly;
  ${mobile({
    width: "90%",
  })}
`;

export const Tab = styled.p`
  padding: 0.5rem 1rem;
  transition: 0.3s ease-in-out;
  border-bottom: ${(props) => props.active && "3px solid red"};
  cursor: pointer;
`;

export const AuthContainer = styled.div`
  width: 35%;
  ${mobile({
    width: "90%",
    padding: 0,
  })}
  margin: 0 auto;
  margin-top: 1.5rem;
  padding: 1rem;
`;
