import React, { useState } from "react";
import {
  ProfileCard,
  Logo,
  LogoImg,
  CustomerWrapper,
  Top,
  DepositModalCover,
  DepositModal,
} from "../../styles/profile.style";
import {
  IconWrapper,
  Input,
  InputWrapper,
  LinkBtn,
  P,
  SubmitBtn,
} from "../../styles/basic.style";
import { TfiMoney } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { privateEndpoints } from "../../config/api.request";
import { upDateBalance } from "../../redux/auth.slice";

const UserCard = ({ setPaymentData }) => {
  const { user, wallet } = useSelector((state) => state.auth);
  const [amount, setAmount] = useState(0);
  const [openDep, setOpenDep] = useState(false);
  const [OpenWith, setOpenWith] = useState(false);
  const [loading, setLoding] = useState(false);
  const [hasGotWalletAdress, sethasGotWalletAdress] = useState(false);
  const [walletData, setWalletData] = useState({});
  const dispatch = useDispatch();

  const handleSubmit = async (type) => {

    if (type === "reserDeposit") {
      sethasGotWalletAdress(false);
      setWalletData({})
      setAmount(0);
    }

    setLoding(true);

    const payload = {
      amount: parseFloat(amount),
    };

    if (amount <= 0) {
      toast.error("Invalid amount", {
        position: "top-right",
        autoClose: 5000,
      });
      setAmount(0);
      setLoding(false);
      return;
    }

    if (type === "deposit") {
      const data = await privateEndpoints.topUp(payload);
      setAmount(0);
      if (data.status === 200){
        sethasGotWalletAdress(true);
        setWalletData(data);
      }
    }

    if (type === "withdrawl") {
      await privateEndpoints.widthdraw(
        payload,
        upDateBalance,
        dispatch
      );
      setAmount(0);
    }

    setLoding(false);
  };

  return (
    <>
      <ProfileCard>
        <Top>
          <Logo>
            <LogoImg src="/img_avatar 2.png" />
          </Logo>
          <CustomerWrapper>
            <P marginBottom="0.5rem" fontSize="0.8rem">
              <span>Full name</span>:{" "}
              <span style={{ fontSize: "0.9rem", fontWeight: "800" }}>
                {user.full_name}
              </span>
            </P>
            <P marginBottom="0.5rem" fontSize="0.8rem">
              <span>Invitation Code</span>:{" "}
              <span style={{ fontSize: "0.9rem", fontWeight: "800" }}>
                {user.referral_id}
              </span>
            </P>
            <P marginBottom="0.5rem" fontSize="0.8rem">
              <span>Balance</span>:{" "}
              <span style={{ fontSize: "0.9rem", fontWeight: "800" }}>
                ${wallet.total_balance}
              </span>
            </P>
          </CustomerWrapper>
        </Top>
        <div style={{ padding: "0 0.5rem 1rem 0.5rem" }}>
          <P style={{ fontSize: "0.8rem", marginBottom: "0.2rem" }}>
            Invitation link :
          </P>
          <P style={{ fontSize: "0.8rem", color: "#980B0B" }}>
            {user.referral_url}
          </P>
        </div>

        <div
          style={{
            padding: "0 0.3rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <LinkBtn
            bgcolor="#DFDFDF"
            color="#750101"
            onClick={() => setOpenDep(true)}
          >
            Deposit
          </LinkBtn>
          <LinkBtn
            bgcolor="#DFDFDF"
            color="#750101"
            onClick={() => setOpenWith(true)}
          >
            Withdrawal
          </LinkBtn>
        </div>

        {openDep && (
          <DepositModalCover onClick={() => setOpenDep(false)}>
            <DepositModal
              onClick={(e) => {
                sethasGotWalletAdress(false)
                e.stopPropagation();
              }}
            >
              {hasGotWalletAdress ? 
              <ShowPaymentView
                data={walletData.data}
              />: 
              <ProcessPayView
                amount={amount}
                setAmount={setAmount}
                handleSubmit={handleSubmit}
                hasData={hasGotWalletAdress}
                loading={loading}
              />
              }
            </DepositModal>
          </DepositModalCover>
        )}

        {OpenWith && (
          <DepositModalCover onClick={() => setOpenWith(false)}>
            <DepositModal
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <P marginLeft="1rem" marginBottom=".5rem">
                Amount
              </P>
              <InputWrapper>
                <IconWrapper>
                  <TfiMoney color="#e34234" />
                </IconWrapper>
                <Input
                  min="0"
                  placeholder="Please Enter amount"
                  value={amount}
                  name="amount"
                  type="number"
                  onChange={(e) => setAmount(e.target.value)}
                />
              </InputWrapper>
              <SubmitBtn
                onClick={() => handleSubmit("withdrawl")}
                disabled={loading}
              >
                {loading ? "Processing Request" : "Withdraw"}
              </SubmitBtn>
            </DepositModal>
          </DepositModalCover>
        )}
      </ProfileCard>
    </>
  );
};

async function copyClip(text) {
  await navigator.clipboard.writeText(text);
  toast.success("address copied successful", {
    position: "top-right",
  });

}

const ShowPaymentView = (props) => {
  console.log(props, "-------- props data")
  return (<>
    <P marginLeft="1rem" marginBottom=".5rem">
      Payment receiving wallets
    </P>
    {props.data.wallet.map((item)=> {
      return (<div style={{padding: "20px"}}>
        <p>Name: {item.name}</p>
        <p>Wallet Address: {item.wallet_address}</p>
        <SubmitBtn style={{width: "80px", marginTop: "10px"}}
            onClick={() => copyClip(item.wallet_address)}
        >
        Copy
      </SubmitBtn>
      </div>)
    })}
    <SubmitBtn
      onClick={() => props.hasData ? props.handleSubmit("reserDeposit") : props.handleSubmit("deposit")}
      disabled={props.loading}
    >
      {props.loading ? "Processing Request" : props.hasData ? "Close" : "Pay"}
    </SubmitBtn>
   
  </>);
}
const ProcessPayView = (props) => {
  return (<>

    <P marginLeft="1rem" marginBottom=".5rem">
      Amount
    </P>
    <InputWrapper>
      <IconWrapper>
        <TfiMoney color="#e34234" />
      </IconWrapper>
      <Input
        min="0"
        placeholder="0"
        value={props.amount === 0 ? "" : props.amount}
        name="amount"
        type="number"
        onChange={(e) => props.setAmount(e.target.value)}
      />
    </InputWrapper>
    <SubmitBtn
      onClick={() => props.handleSubmit("deposit")}
      disabled={props.loading}
    >
      {" "}
      {props.loading ? "Processing Request" : "Pay"}
    </SubmitBtn>
   
  </>);
}

export default UserCard;
