import React from "react";
import Banner from "../components/Others/Banner";
import { P } from "../styles/basic.style";
import { MessageBox, PaymentContainer, PaymentImage, RvBtn } from "../styles/payment.style";

const Payment = ({ data, setPaymentData }) => {

  const paymentData = data.data.wallet;

  // console.log(paymentData);



  return (
    <>
      <Banner page_message="Make Payment"></Banner>

      <MessageBox>{data.message}</MessageBox>
      {Object.keys(paymentData).map((key, index) => {
        return (
          <div key={index}>
            <PaymentContainer>
              <div>
                <PaymentImage src={paymentData[key].icon_image} />

                <P marginLeft=".5rem" fontWeight="700">
                  {paymentData[key].name}
                </P>
              </div>
              <div
                style={{
                  display: "inline-block",
                  wordWrap: "break-word",
                  marginLeft: "1rem",
                  width: "70%",
                }}
              >
                <P marginBottom="0.5rem" fontWeight="700" fontSize="1.2rem">
                  {paymentData[key].amount.amount}{" "}
                  {paymentData[key].amount.currency}
                </P>
                <p style={{ marginLeft: 0 }}>
                  {" "}
                  <strong>Wallet address : </strong>{" "}
                  {paymentData[key].wallet_address}
                </p>
              </div>
            </PaymentContainer>
          </div>
        );
      })}

      <RvBtn
        onClick={() => {
          setPaymentData(null);
        }}
      >
        Go back
      </RvBtn>
    </>
  );
};

export default Payment;
