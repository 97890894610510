import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CryptoJS, { format } from 'crypto-js';
import { publicRequest } from '../config';
import { setAuthToken } from '../config/api.request';

const encrypted_token = localStorage.getItem('access_token') && CryptoJS.AES.decrypt(localStorage.getItem('access_token'), process.env.REACT_APP_KEY, { format: CryptoJS.enc.Utf8});


export const fetchAuthUser = createAsyncThunk('/register', async (credentials, { rejectWithValue }) => {
    try {
        const { data } = await publicRequest.post('/register', credentials)
        return data;
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

})

export const fetchLoginUser = createAsyncThunk('/login', async (credentials, { rejectWithValue }) => {
    try {
        const { data } = await publicRequest.post('/login', credentials)


        return data;
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

})
export const updateUser = createAsyncThunk('/updateUser', async (credentials, { rejectWithValue }) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${credentials.token}`, "Content-type": "application/json"
            }
        }
        delete credentials.token
        const { data } = await publicRequest.patch('/getUser', credentials, config)


        return data;
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

})
export const getUser = createAsyncThunk('/getUser', async (credentials, { rejectWithValue }) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${credentials.token}`, "Content-type": "application/json"
            }
        }
        const data = await publicRequest.get('/getUser', config)
        return data;
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

})


const initialState = {
    loading: false,
    user: JSON.parse(localStorage.getItem('user')),
    access_token: localStorage.getItem('access_token') ? encrypted_token.toString(CryptoJS.enc.Utf8) : '',
    error: null,
    wallet: JSON.parse(localStorage.getItem('wallet')),
    task: JSON.parse(localStorage.getItem('task'))
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem('user');
            state.user = null;
            localStorage.removeItem('wallet');
            state.wallet = null;
            localStorage.removeItem('task');
            state.task = null;
            localStorage.removeItem('access_token');
            state.access_token = '';

        },
        updateDailyClick: (state, action) => {
            let getTask = state.task;
            if (getTask.total_remain_day_click === 1) {
                let getTask = action.payload.data;
                state.task = getTask
                localStorage.setItem('task', JSON.stringify(state.task));
            } else {
                getTask.total_remain_day_click = getTask.total_remain_day_click - 1;
                state.task = getTask
                localStorage.setItem('task', JSON.stringify(state.task));
            }


        },
        upDateBalance: (state, action) => {
            state.wallet.total_balance = action.payload;
            localStorage.setItem('wallet', JSON.stringify(state.wallet));
        },
        getUser: (state, action) => {
            state.wallet.total_balance = action.payload;
            localStorage.setItem('wallet', JSON.stringify(state.wallet));
        }
    },
    extraReducers: {
        [fetchAuthUser.pending]: (state) => {
            state.loading = true;
        },
        [fetchAuthUser.fulfilled]: (state, action) => {

            state.loading = false;
            state.user = action.payload.data.user;
            state.wallet = action.payload.data.wallet;
            state.task = action.payload.data.task;
            state.access_token = action.payload.data.acces_token;
            setAuthToken(state.access_token);
            localStorage.setItem('user', JSON.stringify(state.user));
            localStorage.setItem('access_token', CryptoJS.AES.encrypt(state.access_token, process.env.REACT_APP_KEY).toString());
            localStorage.setItem('wallet', JSON.stringify(state.wallet));
            localStorage.setItem('task', JSON.stringify(state.task));
        },
        [fetchAuthUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [fetchLoginUser.pending]: (state) => {
            state.loading = true;
        },
        [fetchLoginUser.fulfilled]: (state, action) => {

            state.loading = false;
            state.user = action.payload.data.user;
            state.wallet = action.payload.data.wallet;
            state.task = action.payload.data.task;
            state.access_token = action.payload.data.acces_token;
            setAuthToken(state.access_token);
            localStorage.setItem('access_token', CryptoJS.AES.encrypt(state.access_token, process.env.REACT_APP_KEY).toString());
            localStorage.setItem('user', JSON.stringify(state.user));
            localStorage.setItem('wallet', JSON.stringify(state.wallet));
            localStorage.setItem('task', JSON.stringify(state.task));
        },
        [fetchLoginUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [updateUser.pending]: (state) => {
            state.loading = true;
        },
        [updateUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.user = action.payload.data.user;
            localStorage.setItem('user', JSON.stringify(state.user));
        },
        [updateUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [getUser.pending]: (state) => {
            state.loading = true;
        },
        [getUser.fulfilled]: (state, action) => {

            state.loading = false;
            state.user = action.payload.data.data.user;
            state.wallet = action.payload.data.data.wallet;
            state.task = action.payload.data.data.task;
            localStorage.setItem('user', JSON.stringify(state.user));
            localStorage.setItem('wallet', JSON.stringify(state.wallet));
            localStorage.setItem('task', JSON.stringify(state.task));
        },
        [getUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    }

})


export default authSlice.reducer

export const { logout, updateDailyClick, upDateBalance } = authSlice.actions