import styled from "styled-components";

export const BannerProp = styled.div`
  position: absolute;
  top: 8%;
  width: 100%;
  text-align: center;
  font-weight: 800;
  color: white;
`;

export const BannerContainer = styled.div`
  height: 20vh;
  width: 100%;
  margin-bottom: 7%;
  /* background-image: url('https://images.unsplash.com/photo-1514371055400-73fefa1a50a2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1744&q=80');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
  background: #ff2020;
  border-bottom-left-radius: 50% 40%;
  border-bottom-right-radius: 50% 40%;
`;

export const BannerLogo = styled.img`
  background-color: white;
  padding: 0 0 0 0;
  width: 30%;
  max-width: 200px;
  min-width: 150px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -5rem;
`;

export const WelcomeMessage = styled.p`
  text-align: center;
  display: flex;
  color: white;
  justify-content: center;
  font-weight: bolder;
  font-size: 2rem;
  font-family: monospace;
  padding: 10%;
`;

export const PageData = styled.p`
  text-align: center;
  display: flex;
  color: white;
  justify-content: center;
  font-weight: bolder;
  font-size: 2rem;
  font-family: monospace;
`;
