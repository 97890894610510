import styled from "styled-components";
import { Link } from "react-router-dom";
import { handle375 } from "./responsive.style";

export const Container = styled.div`
  width: 90%;
  margin: 5% auto 0 auto;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const IconWrapper = styled.div`
  position: absolute;
  border-right: 2px solid gray;

  padding-right: 0.5rem;
  margin-left: 2rem;
`;

export const Input = styled.input`
  width: 100%;
  padding: 1.3rem;
  font-size: 1rem;
  padding-left: 5rem;
  border-radius: 30px;
  border: none;
  background-color: gainsboro;
  appearance: none;
`;

export const Select = styled.select`
  width: 100%;
  padding: 1.3rem;
  font-size: 1rem;
  padding-left: 1.5rem;
  border-radius: 30px;
  border: none;
  margin-bottom: 1rem;
  background-color: gainsboro;
`;

export const SubmitBtn = styled.button`
  background-color: green; // #e34234;
  padding: 1rem;
  width: 100%;
  border-radius: 30px;
  color: ${(props) => props.color ?? "white"};
  border: none;
  ${handle375({
    marginBottom: "5rem",
  })}
`;
export const P = styled.p`
  font-size: ${(props) => props.fontSize || "1rem"};
  color: ${(props) => props.color || "black"};
  margin-top: ${(props) => props.marginTop || "0"};
  margin-bottom: ${(props) => props.marginBottom || "0"};
  margin-left: ${(props) => props.marginLeft || "0"};
  margin-right: ${(props) => props.margingRight || "0"};
  font-weight: ${(props) => props.fontWeight || ""};
`;

export const LinkBtn = styled.a`
  font-size: ${(props) => props.fontSize || "1rem"};
  background-color: ${(props) => props.bgcolor || "black"};
  color: ${(props) => props.color || "white"};
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  padding: 0.5rem 1rem;
`;

export const StyledTabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c2b7b7bf;
`;

export const StyledTab = styled.p`
  flex: 1;
  font-size: 0.8rem;
  background-color: ${(props) => (props.active ? "red" : "c2b7b7bf")};
  text-align: center;
  color: ${(props) => (props.active ? "white" : "black")};
  border-radius: ${(props) => (props.active ? "30px" : "")};
  padding: 0.7rem;
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.color || "inherit"};
  text-decoration: none;
`;
