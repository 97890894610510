import styled from "styled-components";
import { mobile } from "./responsive.style";

export const ProfileCard = styled.div`
  width: 90%;
  margin: -7rem auto 2rem auto;
  padding-bottom: 1rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;
export const Top = styled.div`
  display: flex;
  padding: 0.5rem;
`;

export const Logo = styled.div`
  flex: 1;
  padding: 0.5rem;
`;
export const LogoImg = styled.img`
  border-radius: 50%;
  width: 200px;
  ${mobile({
    width: "70%",
  })}
`;

export const CustomerWrapper = styled.div`
  flex: 3;
  padding-top: 0.5rem;
`;

export const Lower = styled.div`
  display: flex;
  padding: 0 0.5rem;
  justify-content: space-between;
`;
export const PBoxes = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const PBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.5rem;
  width: 48%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
`;

export const PInfoWrapper = styled.div`
  flex: 2;
`;

export const GoOutBtn = styled.button`
  width: 100%;
  padding: 0.7rem 0;
  border-radius: 20px;
  border: none;
  color: ${(props) => props.color ?? "gray"};
  cursor: pointer;
  border: 1px solid gray;
  background: ${(props) => props.bgColor ?? "white"};
  margin: 2rem 0 4rem 0;
`;

export const DepositModalCover = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
`;

export const DepositModal = styled.div`
  width: 85%;
  padding: 1rem;
  background-color: white;
  border-radius: 10px;
`;
