import styled from "styled-components";

export const CardContainer = styled.div`
  background: #f7f7f7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  padding: 0.5rem;
  position: relative;
  flex: 1;
`;
export const Desc = styled.p`
  color: #807f7f;
`;

export const Digit = styled.p`
  color: #454444;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0.7rem 0;
`;
export const IconWrapper = styled.div`
  position: absolute;
  bottom: 0%;
  right: 2%;
  border-radius: 50%;
`;
export const Icon = styled.img``;
