import React from 'react'


import { CardContainer, Desc, Digit, Icon, IconWrapper } from '../../styles/card.style'

const Card = ({ add_margin, value, headings, img , sign}) => {


    return (
        <CardContainer style={{ marginRight: add_margin }}>
            <Desc>{headings}</Desc>
            <Digit>{sign} {value}</Digit>
            <IconWrapper>

                <Icon src={img} />
            </IconWrapper>
        </CardContainer>
    )
}

export default Card