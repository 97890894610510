import styled from "styled-components";

export const NavContainer = styled.div`
color: white;
position: fixed;
width: 100%;
bottom: 0;
padding: 0.5rem 1rem;
display: flex;
background-color: white;
overflow-x: scroll;
-webkit-box-shadow: 0px -1px 1px rgb(50 50 50 / 24%);
  -moz-box-shadow: 0px -1px 1px rgb(50 50 50 / 24%);
  box-shadow:0px -1px 1px rgb(50 50 50 / 24%);

`

export const BLink = styled.div`
display: flex;
flex-direction: column;
align-items: center;
flex: 1;
`