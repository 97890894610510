import React from "react";
import {
  BannerContainer,
  //   BannerLogo,
  // BannerProp,
  WelcomeMessage,
} from "../../styles/banner.style";
// import { Container } from '../../styles/basic.style'
// import { ProfileCard } from '../../styles/profile.style'
// import UserCard from '../Profile/UserCard'
// import Slider from './Slider'

const Banner = ({ children, page_message }) => {
  return (
    <>
      <BannerContainer>
        <WelcomeMessage> {page_message ?? "WELCOME"} </WelcomeMessage>
      </BannerContainer>
      {/* <BannerProp>{page_message}</BannerProp> */}
      {children}
    </>
  );
};

export default Banner;
