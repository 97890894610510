import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Auth from "./pages/Auth";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
// import Task from './pages/Task';
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "./redux/auth.slice";
import jwt_decode from "jwt-decode";
import Wallet from "./pages/Wallet";
import TopUp from "./pages/TopUp";
import Payment from "./pages/Payment";

const RouteGuard = ({ access_token, redirectPath = "/auth" }) => {
  const dispatch = useDispatch();
  const currentDate = new Date();
  if (!access_token) {
    return <Navigate to={redirectPath} replace />;
  }
  const decoded_token = jwt_decode(access_token);

  if (decoded_token.exp * 1000 < currentDate.getTime()) {
    dispatch(logout());
  }

  return access_token && <Outlet />;
};

function App() {
  const { access_token } = useSelector((state) => state.auth);

  return (
    <>
      <Routes>
        <Route path="/auth" element={<Auth />} />
        <Route path="/:referral-id" element={<Auth />} />
        <Route element={<RouteGuard access_token={access_token} />}>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/topup" element={<TopUp />} />
          <Route path="/payment" element={<Payment />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
